import React from "react";
import twitter from "../images/contacts/twitter.png";
import youtube from "../images/contacts/youtube.png";
import discord from "../images/contacts/discord.png";
import twitch from "../images/contacts/twitch.png";

const Contacts = () => (
  <div className="contacts">
    <a href="https://twitter.com/efgl_fgc">
      <img src={twitter} width={40} alt="twitter" />
    </a>

    <a href="https://www.youtube.com/user/VideoGamersEUeSport">
      <img src={youtube} width={40} alt="youtube" />
    </a>

    <a href=" https://discord.gg/2WXydVz">
      <img src={discord} width={40} alt="discord" />
    </a>

    <a href="https://www.twitch.tv/videogamerseu">
      <img src={twitch} width={40} alt="twitch" />
    </a>
  </div>
);

export default Contacts;
