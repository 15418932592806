import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import "./sidebar.css";
import Contacts from "./contacts";

const Sidebar = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: {eq: "jin.png"}) {
          childImageSharp {
            fixed(width: 332, height: 407) {
              src
            }
          }
        }
      }
    `}

    render={data => (
      <div className="background-image" style={{
        background: `url(${data.file.childImageSharp.fixed.src}) no-repeat, linear-gradient(to bottom right, #000, #101010)`,
        backgroundPositionX: "35%",
      }}>
        <div>
          <h1>
            <Link
              to="/"
            >
              EFGL
            </Link>
          </h1>
          <Contacts />
        </div>

        <div className="sidebar-content">
          <div style={{overflow:"hidden",}}>
            <Link to="/news/">News</Link>
            <Link to="/facts/">Facts</Link>
            <Link to="/tournaments/">Tournaments</Link>
            <Link to="/standings/">Standings</Link>
            <Link to="/stats/">Stats</Link>
            <Link to="/gallery/">Gallery</Link>
            <Link to="/rules/">Rules</Link>
          </div>
        </div>
      </div>
    )}
  />
);

export default Sidebar;
