import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import Sidebar from "./sidebar";
import "./layout.css";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className='layout'>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "description", content: "Estonian Fighting Game League" },
            { name: "keywords", content: "Estonian, Fighting, Game, League, tekken, street, fighter, soul, calibur, mortal, kombat" },
            { name: "theme-color", content: "#be512a"},
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className='sidebar'>
          <Sidebar />
        </div>
        <div className='content'>
          {children}
        </div>
        {/*<div className='footer'>
          <Footer></Footer>
        </div>*/}
      </div>
    )}
  />
);


Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
